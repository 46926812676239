import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>

            I enjoy writing code that can solve  <span className="purple"> real world problems. </span>

            For example, I've developed applications like: 
            <span className="purple"> AI Online Suicide Detector, </span> 
            <span className="purple"> Muscle Seizure Detector, </span>         
            and my startup; 
            <span className="purple"> Husi, </span>
            which I plan on launching soon.

<br></br>
            <br />I plan on pursuing a career in software development, with a goal of working in 
            <span className="purple"> quantatative finance.  </span> 
            <br />
<br></br>

            In my personal life some things I'm <span className="purple"> passionate </span>  about are:
            <br />
            <br />
          </p>
          
          <ul>
            <li className="about-activity">
              <ImPointRight /> Weightlifting / Bodybuilding 
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing Sports 
            </li>
            <li className="about-activity">
              <ImPointRight /> Investing / Financial Literacy
            </li>
            <li className="about-activity">
              <ImPointRight /> Staying Healthy!
            </li>
          </ul>

          <p style={{ textAlign: "justify" }}>
            <br />
            Some Fun Facts About Me:

            <ul>
              <br></br>
            <li className="about-activity">
              <ImPointRight /> I barbell row 245 for reps
            </li>
            <li className="about-activity">
              <ImPointRight /> I played soccer for 10 years of my life
            </li>
            <li className="about-activity">
              <ImPointRight /> I'm an absolute beast of a chef
            </li>
            <li className="about-activity">
              <ImPointRight /> I want to move Switzerland one day!
            </li>
          </ul>

            <br />
            <br />
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            The Happiness of Your Life Depends Upon The Quality Of Your Thoughts{" "}
          </p>
          <footer className="blockquote-footer">Marcus Aurelius</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;